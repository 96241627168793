function rgb(r, g, b) {
    return "rgb(" + r + "," + g + "," + b + ")";
}

function mixRGB(r1, g1, b1, r2, g2, b2, percent) {
    let r = r1 + (r2 - r1) * percent;
    let g = g1 + (g2 - g1) * percent;
    let b = b1 + (b2 - b1) * percent;
    return rgb(r, g, b)
}

function remToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function calcPosition(element) {
    let top = element.getBoundingClientRect().top;
    let bottom = element.getBoundingClientRect().bottom;
    let topPercent = 1 - (top / window.innerHeight);
    if (topPercent > 1) { topPercent = 1 } else if (topPercent < 0) { topPercent = 0 }
    let bottomPercent = 1 - (bottom / window.innerHeight);
    if (bottomPercent > 1) { bottomPercent = 1 } else if (bottomPercent < 0) { bottomPercent = 0 }
    return { topPercent, bottomPercent, top, bottom }
}

// animate move right
const observer_right = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("move_right");
        } else {
            entry.target.classList.remove("move_right");
        }
    });
});

// animate slide
document.querySelectorAll('.fly-right').forEach((e) => {
    observer_right.observe(e)
})

document.querySelectorAll('section').forEach((section) => {
    window.addEventListener("scroll", (e) => {
        let { topPercent, top, bottomPercent } = calcPosition(section);
        let myContainer = section.querySelector(".content");
        if (topPercent < 1) {
            myContainer.style.opacity = topPercent;
            section.style.backgroundColor = mixRGB(60, 63, 77, 43, 45, 54, topPercent);
            myContainer.style.transform = "translateY(" + (30 * (1 - topPercent)) + "vh)";
        } else if (bottomPercent < 1) {
            myContainer.style.transform = "translateY(" + (-30 * bottomPercent) + "vh)";
        }
    })
})